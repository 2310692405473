import { GetServerDataReturn, navigate } from 'gatsby';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile, isSafari, isChrome } from 'react-device-detect';
import { IPost, IPostTag } from 'src/types/strapi/posts';

import BlogArticles from '../components/BlogPage/components/BlogArticles';
import FlipScreen from '../components/flip-screen';
import Footer from '../components/footer';
import Layout from '../components/layout';
import Loader from '../components/loader/loaderComponent';
import PageSection from '../components/PageSection';
import { GLOBAL_QUERY } from '../constants';
import { useClassnames } from '../hooks/use-classnames';
import FlipLogoIcon from '../images/flip-logo.svg';
import SberCloudLogoIcon from '../images/sbercloud-logo.svg';
import { TPageProp } from '../types';
import { IFlip } from '../types/strapi/flip';
import { IGlobalServerRuntimeData } from '../types/strapi/global';
import { mediaUrl } from '../utils';


import { getPosts } from './news/[code]';

import './flip.css';

const query = `
  query getFlip($locale: String) {
    ${GLOBAL_QUERY}
    flip(locale: $locale) {
      isHidden
      seo {
        metaTitle
        metaDescription
      }
      first_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      eighth_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      fifth_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      fourth_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      second_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      seventh_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      sixth_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      third_screen {
        background {
          url
        }
        backgroundOgg {
          url
        }
        mobileBackground {
          url
        }
        primary_header
        primary_text
        primary_text_float
        secondary_header
        secondary_text
        secondary_text_float
      }
      tags {
        id
        value
        text
      }
    }
  }
`;

const DEBOUNCE_TIMER = 50;

type TProp = TPageProp<IServerRuntimeData>;

const FlipPage: React.FC<TProp> = ({ serverData }) => {
    const cn = useClassnames();
    const [isLoading, setIsLoading] = useState(true);
    const videoSource = useMemo(() => {
        const desktopBackground = isSafari || isChrome ? 'background' : 'backgroundOgg';

        return isMobile ? 'mobileBackground' : desktopBackground;
    }, []);

    const {
        seo,
        first_screen,
        third_screen,
        fourth_screen,
        fifth_screen,
        sixth_screen,
        seventh_screen,
        eighth_screen,
        posts
    } = serverData.flip;

    const registerVideo = useCallback((boundSelector: string, videoBlob: string) => {
        const bound = document.querySelector(boundSelector);
        const video = document.querySelector(`${boundSelector} video`) as HTMLVideoElement;

        video?.setAttribute('src', videoBlob);

        if(isMobile) {
            return;
        }

        const primaryTextBlock = bound?.querySelector('.flip__text-wrapper_primary') as HTMLElement;
        const primaryText = primaryTextBlock?.querySelector('.flip__screen-text') as HTMLElement;
        const secondaryTextBlock = bound?.querySelector('.flip__text-wrapper_secondary') as HTMLElement;

        const scrollVideo = debounce(() => {
            if(video?.duration) {
                const boundClientRectTop = bound?.getBoundingClientRect().top || 0;
                const boundScrollHeight = bound?.scrollHeight || 0;
                const distanceFromTop = window.scrollY + boundClientRectTop;
                const rawPercentScrolled = (window.scrollY - distanceFromTop) / (boundScrollHeight - window.innerHeight);
                const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1);

                if(primaryTextBlock && secondaryTextBlock) {
                    if(percentScrolled >= 0.5) {
                        const headerHeight = 60;

                        primaryTextBlock.style.transform = `translateY(-${primaryTextBlock.offsetTop - headerHeight}px)`;
                        secondaryTextBlock.style.opacity = '1';

                        if(primaryText) {
                            primaryText.style.opacity = '0';
                        }
                    } else {
                        primaryTextBlock.style.transform = 'translateY(0px)';
                        secondaryTextBlock.style.opacity = '0';

                        if(primaryText) {
                            primaryText.style.opacity = '1';
                        }
                    }
                }

                video.currentTime = video.duration * percentScrolled;
            }
            requestAnimationFrame(scrollVideo);
        }, DEBOUNCE_TIMER);

        requestAnimationFrame(scrollVideo);
    }, []);

    useEffect(() => {
        if(isMobile !== null) {
            const preloadVideos = [
                fetch(mediaUrl(first_screen[videoSource].url)).then((response) => response.blob()),
                fetch(mediaUrl(third_screen[videoSource].url)).then((response) => response.blob()),
                fetch(mediaUrl(fourth_screen[videoSource].url)).then((response) => response.blob()),
                fetch(mediaUrl(fifth_screen[videoSource].url)).then((response) => response.blob()),
                fetch(mediaUrl(sixth_screen[videoSource].url)).then((response) => response.blob()),
                fetch(mediaUrl(seventh_screen[videoSource].url)).then((response) => response.blob()),
                fetch(mediaUrl(eighth_screen[videoSource].url)).then((response) => response.blob())
            ];

            Promise.all(preloadVideos).then((data) => {
                setIsLoading(false);
                data.map((screen, i) => registerVideo(`#bound-${i + 1}`, URL.createObjectURL(screen)));
                // eslint-disable-next-line no-console
            }).catch((e) => console.log(e));
        }
    }, [isMobile]);

    useEffect(()=>{
      navigate("/fleet");
    },[]);

    return null;

    return (
        <div className="flip__page">
            <Layout
                seo={seo}
                navPanel={serverData.navPanel}
                global={serverData.global}
                respondForm={serverData.respondForm}
            >
                {isLoading ? (
                    <div className={cn('loader__wrapper')}><Loader stopColor="#BDFFF8" /></div>
                ) : (
                    <div className={cn('flip__wrapper', { 'flip__wrapper_mobile': isMobile })}>
                        <FlipScreen isMobile={isMobile} data={first_screen} id="bound-1" />
                        <FlipScreen isMobile={isMobile} data={third_screen} id="bound-2" />
                        <FlipScreen isMobile={isMobile} data={fourth_screen} id="bound-3" />
                        <FlipScreen isMobile={isMobile} data={fifth_screen} id="bound-4" />
                        <FlipScreen isMobile={isMobile} data={sixth_screen} id="bound-5" icon={SberCloudLogoIcon} />
                        <FlipScreen isMobile={isMobile} data={seventh_screen} id="bound-6" />
                        <FlipScreen isMobile={isMobile} data={eighth_screen} id="bound-7" />
                        <img className={cn('flip__logo')} src={FlipLogoIcon} />
                        {posts && posts.length > 0 && (
                            <PageSection>
                                <BlogArticles items={posts} carousel={true} head={true} headText="Новости по теме"  headBolder={true} />
                            </PageSection>
                        )}
                        <Footer className={cn('flip__footer')} data={serverData.footer} />
                    </div>
                )}
            </Layout>
        </div>
    );
};

export default FlipPage;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    flip: IFlip
}

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const flipResponse = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await flipResponse.json();

        if(data.data.flip.isHidden) {
            return {
                status: 404
            };
        }

        return {
            props: {
                flip: {
                    ...data.data.flip,
                    posts: await getPosts(-1, data.data.flip.tags.map((tag) => tag.value))
                },
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
